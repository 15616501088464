import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import "./App.scss";
import CoursesList from "./public/courses/coursesList/CoursesList";
import CourseContent from "./public/courses/courseContent/CourseContent";
import Layout from "./_shared/components/layout/Layout";
import { Component, useEffect } from "react";
import GetAllGrades from "./public/_shared/function/GetAllGrades";
import AppLoader from "./_shared/components/loader/AppLoader";
import ReactPixel from "react-facebook-pixel";
import NotFound from "./public/notFound/NotFound";

const advancedMatching = { em: "amboara@misaina.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init(process.env.REACT_FACEBOOK_PIXEL, advancedMatching, options);
ReactPixel.grantConsent();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      grades: [],
      loadingData: true,
    };
  }

  async componentDidMount() {
    const { data } = await GetAllGrades();
    if (data) {
      this.setState({ grades: data.grades, loadingData: false });
    }
    ReactPixel.pageView(); // For tracking page view
  }

  render() {
    const { grades, loadingData } = this.state;

    if (loadingData) {
      return <AppLoader />;
    }

    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Layout grades={grades} />}>
              <Route path="not-found" element={<NotFound backLevel={-2} />} />
            </Route>
            <Route path="/" element={<Redirect />}>
              <Route path=":gradeSlug" element={<Redirect />} />
            </Route>
            <Route
              path=":gradeSlug/:subjectSlug"
              element={<Layout grades={grades} />}
            >
              <Route path="*" element={<NotFound backLevel={-1} />} />
              <Route index element={<CoursesList />} />
              <Route
                path=":courseId--:courseSlug"
                element={<CourseContent />}
              />
            </Route>
            {/* Use this format to loadData in courses list, course content, ... */}
            {/* <Route
              element={<Team />}
              path="teams/:teamId"
              loader={async ({ params }) => {
                return fetch(
                  `/fake/api/teams/${params.teamId}.json`
                );
              }}
              action={async ({ request }) => {
                return updateFakeTeam(await request.formData());
              }}
              errorElement={<ErrorBoundary />}
            /> */}
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

function Redirect() {
  const { gradeSlug = "" } = useParams();

  useEffect(() => {
    window.location.href = "https://www.mahay.mg/" + gradeSlug;
  });

  return <></>;
}

export default App;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

export default class SubSubjectCard extends Component {
  static propType = {
    subSubject: PropTypes.array,
    courses: PropTypes.array,
    grade: PropTypes.string,
    subject: PropTypes.string,
  };

  render() {
    const {
      subSubject: { subSubject, courses },
      grade,
      subject,
    } = this.props;

    const title = subSubject && subSubject.name;

    const sertedCourses = courses.sort(
      (courseA, courseB) => courseA.chapter - courseB.chapter
    );

    return (
      <div className="courses-card">
        {courses && courses.length ? (
          <>
            <Card>
              {title && (
                <Card.Header>
                  <Card.Title>
                    <h2 className="h2-yellow">{title}</h2>
                  </Card.Title>
                </Card.Header>
              )}
              <Card.Body>
                <ListGroup variant="flush">
                  {sertedCourses &&
                    sertedCourses.map((content, index) => (
                      <div key={index}>
                        <Link
                          to={`/${grade}/${subject}/${content.id}--${content.slug}`}
                        >
                          <ListGroup.Item
                            key={index}
                            className="subheading-light"
                          >
                            {content.chapter}. {content.title}
                          </ListGroup.Item>
                        </Link>
                      </div>
                    ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </>
        ) : (
          []
        )}
      </div>
    );
  }
}

export default async function retrieveDataFromAPI(endpoint) {
  const data = await fetch(`${process.env.REACT_APP_SERVER_URL}${endpoint}`)
    .then(function (response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(function (res) {
      return { data: res, status: "ok" };
    })
    .catch(function (error) {
      return { status: "not-ok", data: error };
    });
  return data;
}

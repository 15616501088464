import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";
import { ReactComponent as YoutubeSVG } from "./social-media-icon/youtube-black.svg";
import { ReactComponent as FacebookSVG } from "./social-media-icon/facebook-black.svg";
import { ReactComponent as TiktokSVG } from "./social-media-icon/tiktok-black.svg";
import { ReactComponent as InstagramSVG } from "./social-media-icon/instagram-black.svg";

class PublicFooter extends Component {
  render() {
    const { grades } = this.props;
    return (
      <footer className="public-footer">
        <div className="div-social-media">
          <div className="classes">
            {grades.map((grade) => (
              <Link to={`/${grade.slug}`} key={grade.id} className="grade">
                {grade.name}
              </Link>
            ))}
          </div>

          <div className="media">
            <label>Retrouve-nous aussi sur</label>
            <div className="social">
              <a
                className="social-icon"
                href="https://web.facebook.com/webmahaymg"
                title="Page Facebook"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookSVG fill="#FFDE59" />
              </a>
              <a
                className="social-icon"
                href="https://instagram.com/mahaymg?igshid=NDRkN2NkYzU="
                title="Compte Instagram"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramSVG fill="#FFDE59" />
              </a>
              <a
                className="social-icon"
                href="https://www.youtube.com/channel/UCO686xG3APrSb1ovutfXagA"
                title="Chaine Youtube"
                target="_blank"
                rel="noreferrer"
              >
                <YoutubeSVG fill="#FFDE59" />
              </a>
              <a
                className="social-icon"
                href="https://tiktok.com/@mahay.mg"
                title="Compte Tik Tok"
                target="_blank"
                rel="noreferrer"
              >
                <TiktokSVG fill="#FFDE59" />
              </a>
            </div>
          </div>
        </div>
        <div className="copyright-footer">
          <span>Créé par Misaina Inc. avec ❤️ | </span>
          <span className="ms-1">Version {process.env.REACT_APP_VERSION}</span>
        </div>
      </footer>
    );
  }
}
export default withAuth0(PublicFooter);

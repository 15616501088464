export default function simplifyString(text) {
  let simplified = text.toLowerCase();
  simplified = simplified.split(/[éèêë]/g).join("e");
  simplified = simplified.split(/[àäâáa]/g).join("a");
  simplified = simplified.split(/[ùûü]/g).join("u");
  simplified = simplified.split(/[ìîï]/g).join("i");
  simplified = simplified.split(/[ôöò]/g).join("o");
  simplified = simplified.split(/[ÿ]/g).join("y");
  simplified = simplified.split(/[œ]/g).join("oe");
  simplified = simplified.split(/[æ]/g).join("ae");
  simplified = simplified.split(/[ ,;/':._!?*"'()]/g).join(" ");
  return simplified;
}
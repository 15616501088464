import React, { Component } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import withNavigate from "../../hooks/withNavigate";

class SubjectMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentGrade: null,
      currentSubjectSlug: props.subjectSlug,
      currentGradeSlug: props.gradeSlug,
    };
  }

  loadData = (gradeSlug, subjectSlug) => {
    const { grades } = this.props;

    grades.forEach((grade) => {
      if (grade.slug === gradeSlug) {
        this.selectGrade(gradeSlug);
        this.setState({
          currentGrade: grade,
          currentSubjectSlug: subjectSlug,
          currentGradeSlug: gradeSlug,
        });
      }
    });
  };

  componentDidMount() {
    const { gradeSlug, subjectSlug } = this.props;

    this.loadData(gradeSlug, subjectSlug);
  }

  selectGrade = (selectedGradeSlug) => {
    const { grades } = this.props;

    grades.forEach((grade) => {
      if (grade.slug === selectedGradeSlug) {
        this.setState({ currentGrade: grade, currentGradeSlug: grade.slug });
      }
    });
  };

  selectSubject = (selectSubjectSlug) => {
    const { currentGradeSlug } = this.state;
    const { navigate } = this.props;
    navigate(`/${currentGradeSlug}/${selectSubjectSlug}`);
  };

  render() {
    const { grades, gradeSlug } = this.props;
    const { currentGrade, currentGradeSlug, currentSubjectSlug } = this.state;

    if (!currentGrade) {
      return <></>;
    }

    return (
      <div className="subject-menu">
        <NavDropdown
          title={currentGrade.name}
          className="grade-name h2-dark"
          onSelect={this.selectGrade}
        >
          {grades.map((grade, index) => (
            <NavDropdown.Item
              key={index}
              eventKey={grade.slug}
              active={currentGradeSlug === grade.slug}
              className="text-link"
            >
              {grade.name}
            </NavDropdown.Item>
          ))}
          {/* Use divider to split section (primaire, secondaire) */}
          {/*  <NavDropdown.Divider /> */}
        </NavDropdown>

        <Nav
          className="flex-column"
          onSelect={this.selectSubject}
          variant="dark"
        >
          {currentGrade.subjects &&
            currentGrade.subjects.map((subject, index) => (
              <Nav.Item key={index} className={`link-item`}>
                <Nav.Link
                  className="h3-light"
                  eventKey={subject.slug}
                  active={
                    gradeSlug === currentGradeSlug &&
                    subject.slug === currentSubjectSlug
                  }
                >
                  <img
                    src={`/icons/subjects/${subject.slug}.svg`}
                    alt={`${subject.slug}`}
                    className="subject-image"
                  />
                  {subject.name}
                </Nav.Link>
              </Nav.Item>
            ))}
        </Nav>
      </div>
    );
  }
}

export default withNavigate(SubjectMenu);

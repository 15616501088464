import React, { PureComponent } from "react";
import CoursesContainer from "./CoursesContainer";
import { Helmet } from "react-helmet";
import ReactPixel from "react-facebook-pixel";
import withParams from "../../../_shared/hooks/withParams";
import GetAllCourseClassSubject from "../../_shared/function/GetAllCourseClassSubject";
import DataLoader from "../../../_shared/components/loader/DataLoader";
import withNavigate from "../../../_shared/hooks/withNavigate";

class CoursesList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      courses: [],
      subSubjects: [],
      exercise: [],
      sujetsType: [],
      loadingData: true,
      coursWithSubSubject: [],
      coursNoSubSubject: [],
    };
  }

  loadData = async (gradeSlug, subjectSlug) => {
    const { navigate } = this.props;
    const { status, data } = await GetAllCourseClassSubject(
      gradeSlug,
      subjectSlug
    );
    if (status === "not-ok") {
      navigate("/not-found"); // Refactor this and use context to display not found page
    } else {
      const dataCourse = data;
      const { courses } = dataCourse;
      this.setState({
        courses,
        subjectSlug,
        gradeSlug,
      });
      this.setState({ loadingData: false });
      ReactPixel.track("ViewContent", courses);
    }
  };

  async componentDidMount() {
    const {
      params: { subjectSlug, gradeSlug },
    } = this.props;

    this.loadData(gradeSlug, subjectSlug);
  }

  componentDidUpdate() {
    const {
      params: { subjectSlug, gradeSlug },
    } = this.props;

    if (
      this.state.subjectSlug !== subjectSlug ||
      this.state.gradeSlug !== gradeSlug
    ) {
      this.setState({ loadingData: true });
      this.loadData(gradeSlug, subjectSlug);
    }
  }

  render() {
    const {
      courses: { grade, subject, subSubjects },
      loadingData,
    } = this.state;

    if (loadingData) {
      return <DataLoader />;
    }
    const subjectSlug = subject.slug;
    const gradeSlug = grade.slug;

    return (
      <div className="courses-list">
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content={`Retrouve tous les cours de ${subject.name} pour la classe de ${grade.name} ici`}
          />
          <title>
            Les cours de {subject.name} pour {grade.name}
          </title>
          <link
            rel="canonical"
            href={`https://web.mahay.mg/${gradeSlug}/${subjectSlug}`}
          />
        </Helmet>
        <div className="subject-title justify-content-center">
          <img
            src={`/icons/subjects/${subjectSlug}.svg`}
            alt={`${gradeSlug}`}
            className="subject-image"
          />
          <h1>
            {subject.name} • {grade.name}
          </h1>
        </div>
        <CoursesContainer
          courses={subSubjects}
          gradeSlug={gradeSlug}
          subjectSlug={subjectSlug}
        />
      </div>
    );
  }
}

export default withNavigate(withParams(CoursesList));

import React, { Component } from "react";
import withParams from "../../hooks/withParams";
import { Navbar } from "react-bootstrap";
import SubjectMenu from "./SubjectMenu";
import { FiMenu } from "react-icons/fi";

class SideBarMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { grades } = this.props;
    const {
      params: { gradeSlug, subjectSlug },
    } = this.props;

    return (
      <>
        <div className="sidebar-menu d-lg-block">
          <Navbar
            expand="lg"
            className="dl-lg-none"
            collapseOnSelect
            variant="dark"
          >
            <Navbar.Brand>
              <img src="/logo.svg" alt="Mahay.mg logo" className="logo" />
            </Navbar.Brand>
            <Navbar.Toggle>
              <FiMenu />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <SubjectMenu
                grades={grades}
                gradeSlug={gradeSlug}
                subjectSlug={subjectSlug}
              />
            </Navbar.Collapse>
          </Navbar>
        </div>
      </>
    );
  }
}

export default withParams(SideBarMenu);

import React from "react";
import { Spinner } from "react-bootstrap";

export default function AppLoader() {
  return (
    <div className="app-loader d-flex justify-content-center align-items-center">
      {/* <Spinner animation="grow" variant="secondary" /> */}
      <img src="/app-loader.gif" alt="Mahay app loader" />
    </div>
  );
}

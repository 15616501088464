import React, { Component } from "react";
import { Link, Outlet } from "react-router-dom";
import SideBarMenu from "./SideBarMenu";
import PublicFooter from "./PublicFooter";
import withParams from "../../hooks/withParams";
import { Breadcrumb } from "react-bootstrap";

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      grade: {},
      subject: {},
    };
  }

  loadData = (gradeSlug, subjectSlug) => {
    const { grades } = this.props;

    grades.forEach((grade) => {
      if (grade.slug === gradeSlug) {
        grade.subjects.forEach((subject) => {
          if (subjectSlug && subject.slug === subjectSlug) {
            this.setState({ subject });
          }
          this.setState({ grade, subjectSlug, gradeSlug });
        });
      }
    });
  };

  componentDidMount() {
    const {
      params: { gradeSlug, subjectSlug },
    } = this.props;

    this.loadData(gradeSlug, subjectSlug);
  }

  componentDidUpdate() {
    const {
      params: { gradeSlug, subjectSlug },
    } = this.props;

    if (
      this.state.subjectSlug !== subjectSlug ||
      this.state.gradeSlug !== gradeSlug
    ) {
      this.loadData(gradeSlug, subjectSlug);
    }
  }

  render() {
    const { grades } = this.props;
    const { grade, subject, gradeSlug, subjectSlug } = this.state;

    return (
      <div className="layout">
        <SideBarMenu grades={grades} />
        <div className="content">
          <div className="bg" />
          {/* <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>Accueil</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active={!subjectSlug}>
              {subjectSlug ? (
                <Link to={`/${grade.slug}`}>{grade.name}</Link>
              ) : (
                <>{grade.name}</>
              )}
            </Breadcrumb.Item>
          </Breadcrumb> */}
          <div className="outlet-content">
            <Outlet />
          </div>
        </div>
        {/* <PublicFooter grades={grades} /> */}
      </div>
    );
  }
}
export default withParams(Layout);

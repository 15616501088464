import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class NotFoundContent extends Component {
  render() {
    const { backLevel = -1 } = this.props;
    return (
      <div className="not-found d-flex flex-column justify-content-center align-items-center text-center">
        <p className="h1-dark">Oups !</p>
        <p className="subtitle-dark">Cette page n'existe pas</p>
        <p>
          Tu es perdu ?{" "}
          <Link to={backLevel}>Retourne à la page précédente</Link>
        </p>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import DataLoader from "../../../_shared/components/loader/DataLoader";
import withParams from "../../../_shared/hooks/withParams";
import NotionContent from "../../_shared/components/NotionContent";
import GetCourseById from "../../_shared/function/GetCourseById";
import withNavigate from "../../../_shared/hooks/withNavigate";

class CourseContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      course: null,
      loadingData: true,
    };
  }

  async componentDidMount() {
    const { navigate } = this.props;
    const {
      params: { courseId },
    } = this.props;
    const { status, data } = await GetCourseById(courseId);
    if (status === "not-ok") {
      navigate("/not-found"); // Refactor this and use context to display not found page
    } else {
      const { course } = data;
      this.setState({
        course,
        loadingData: false,
      });
      ReactPixel.track("ViewContent", course);
    }
  }

  render() {
    const { loadingData, course } = this.state;
    const {
      params: { subjectSlug, gradeSlug },
    } = this.props;

    if (loadingData) {
      return <DataLoader />;
    }

    const pageUrl = `https://web.mahay.mg/${gradeSlug}/${subjectSlug}/${course.slug}`;
    const facebookAppId = process.env.REACT_FACEBOOK_PIXEL;
    return (
      <div className="course-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{course.SEOTitle}</title>

          <link rel="canonical" href={pageUrl} />

          <meta name="title" content={course.SEOTitle} />
          <meta name="description" content={course.SEODescription} />

          <meta property="fb:app_id" content={facebookAppId} />
          <meta property="og:url" content={pageUrl} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={course.SEOTitle} />
          <meta property="og:description" content={course.SEODescription} />
          <meta property="og:image" content={course.seoImage} />
          <meta property="og:site_name" content="MAHAY.MG" />

          <meta name="keywords" content={course.seoKeyword} />
        </Helmet>
        <div className="course-container">
          <Link to={`/${gradeSlug}/${subjectSlug}`} className="btn-back">
            &lt; Retour
          </Link>
          <div id="pdf" className="div-content-to-pdf">
            <NotionContent idContent={course.content} />
          </div>
        </div>
      </div>
    );
  }
}
export default withNavigate(withParams(CourseContent));

import React, { Component } from "react";
import { Container, Button, FormControl, InputGroup } from "react-bootstrap";
import { FiX } from "react-icons/fi";
import SubSubjectCard from "../../_shared/components/SubSubjectCard";
import simplifyString from "./../../../_shared/helpers/simplifyString";
class CoursesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
    };
  }

  handleChangeSearchValue = (e) => {
    const searchValue = e.target.value;
    this.changeSearchValue(searchValue);
  };

  handleClearValue = () => {
    this.changeSearchValue("");
  };

  changeSearchValue = (value) => {
    this.setState({ value: value });
  };

  render() {
    const { courses, gradeSlug, subjectSlug } = this.props;
    const { value } = this.state;
    let coursesSearchResult = [];

    if (courses) {
      if (simplifyString(value) === "") {
        coursesSearchResult = courses;
      } else if (simplifyString(value) !== "") {
        courses.forEach((courses, index) => {
          coursesSearchResult.push(Object.assign({}, courses));
          coursesSearchResult[index].courses = [];
          courses.courses.forEach((course) => {
            if (simplifyString(course.seoTitle).includes(simplifyString(value)))
              coursesSearchResult[index].courses.push(course);
          });
        });
      }
    }

    return (
      <Container className="d-flex flex-column justify-content-centerr">
        <div className="mt-3 mb-5 d-flex justify-content-center">
          <InputGroup className="search-input">
            <FormControl
              placeholder="Rechercher"
              aria-label="Rechercher"
              className="no-select"
              value={value}
              onChange={this.handleChangeSearchValue}
            />
            <Button
              onClick={this.handleClearValue}
              className="no-select search-button"
            >
              <FiX />
            </Button>
          </InputGroup>
        </div>
        <div className="courses-list-content align-self-center">
          <div className="tab-content">
            {coursesSearchResult &&
              coursesSearchResult.map((subSubject, index) => (
                <SubSubjectCard
                  key={index}
                  subSubject={subSubject}
                  grade={gradeSlug}
                  subject={subjectSlug}
                />
              ))}
          </div>
        </div>
      </Container>
    );
  }
}
export default CoursesContainer;

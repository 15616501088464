import React, { Component } from "react";
import { NotionRenderer } from "react-notion";
import GetPageNotion from "../function/GetPageNotion";

import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";

export default class NotionContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockMap: {},
    };
  }

  async componentDidMount() {
    const blogData = await GetPageNotion(this.props.idContent);
    this.setState({ blockMap: blogData });
  }

  render() {
    return <NotionRenderer fullPage blockMap={this.state.blockMap} />;
  }
}
